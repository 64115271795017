






























































































import Featured from '@/components/Featured.vue';
import InquiryForm from '@/components/InquiryForm.vue';
import FeaturePoint from '@/components/FeaturePoint.vue';
import { AsyncView, RouteMeta } from 'client-website-ts-library/plugins';
import { API } from 'client-website-ts-library/services';
import { Blog, MapSettings, MapMarker } from 'client-website-ts-library/types';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import wiki from 'wikipedia';
import Map from '@/components/Map/Map.vue';
import HeroV2 from '@/components/HeroV2.vue';

@Component({
  components: {
    InquiryForm,
    Featured,
    Map,
    FeaturePoint,
    HeroV2,
  },
})
export default class BlogDetails extends Mixins(AsyncView) {
  public blog: Blog | null = null;

  public summary: any | null = null;

  public infobox: any | null = null;

  public mapSettings: MapSettings | null = null;

  mounted() {
    const blogId = this.$route.params.id;

    API.Blogs.Get(blogId).then((blog) => {
      this.blog = blog;
      this.$emit('updateView');
    });
  }

  @Watch('blog')
  async getWikiArticle() {
    try {
      const link = this.wikiLink();
      if (!link) return;

      const page = await wiki.page(link);
      this.summary = await page.summary();
      this.infobox = await page.infobox();

      const address = `${this.infobox.name} ${this.infobox.postcode} ${this.infobox.state.toUpperCase()}`;
      this.mapSettings = new MapSettings({
        Markers: [
          new MapMarker({
            Address: address,
            Title: address,
          }),
        ],
      });
    } catch (_error) {
      // ignored
    }
  }

  isSuburb() {
    return this.blog?.FormattedType === 'Suburb';
  }

  wikiLink() {
    const links = this.blog?.ExternalLinks ?? [];
    // eslint-disable-next-line no-restricted-syntax
    for (const link of links) {
      if (link.Domain === 'en.wikipedia.org') {
        return link.Url.substring(link.Url.lastIndexOf('/') + 1);
      }
    }

    return null;
  }

  stripHtml(html: string, limit: number): string {
    const tmp = document.createElement('div');

    tmp.innerHTML = html;

    const text = tmp.innerText;

    return text.length < (limit * 1.2) ? text : `${tmp.innerText.substring(0, limit)}...`;
  }

  public head(): RouteMeta {
    return {
      title: `${this.blog?.Title} - ${this.blog?.Subtitle}`,
      description: this.stripHtml(this.blog?.Paragraph1 ?? '', 100),
      image: this.blog?.Images.length ? this.blog?.Images[0].Preview.Url : undefined,
    };
  }

  scrollTo(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleError(err: Response) {
    if (err.status === 403) {
      this.$router.push({
        path: '/blogs',
        query: {
          next: `/blogs/${this.$route.params.id}`,
        },
      });
    }
  }
}
